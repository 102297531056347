<template>
  <div v-html="pageData.inviteRule" style="font-size: 18px"></div>
</template>

<script>
import { getInviteFriendsInfo } from "@/api/api";

export default {
  name: "InviteRule",
  data() {
    return {
      loading: true,
      pageData: {},
    };
  },
  created() {
    //邀请好友
    getInviteFriendsInfo().then((res) => {
      this.pageData = res.data;
      this.loading = false;
    });
  },
  methods: {},
};
</script>
